@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400&display=swap');
/* Fira Sans */
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Source+Serif+Pro&display=swap');
/* Oswald, Source Serif Pro */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
/* Josefin Sans */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@1,300;1,400&display=swap');
/* Noto Serif Display */
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
/* Lobster */
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
/* Mukta */

body {
    background-color: #2a2f34;
    height: 100%;
}

/* NAV STYLES */
.navbar {
    background-color: #131313;
}

.nav-link {
    margin-right: 2rem;
}

/* PROJECT PAGE STYLES */
.project-page-title {
    font-size: 4rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
}

/* ABOUT STYLES */
.skills-icon {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 auto;
  }

.about-header-text {
    margin-top: 6rem;
    margin-bottom: 3rem;
}
  
